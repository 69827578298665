var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "div",
        { staticClass: "popup_cont" },
        [
          ["JP", "VN", "TH", "MY", "PH"].indexOf(_vm.memberDetail.userCtrCd) ===
          -1
            ? [
                _c("h1", { staticClass: "page_title mt0" }, [
                  _vm._v(_vm._s(_vm.pageTitle)),
                ]),
              ]
            : [
                _vm.msgVo.doStsCd === "1"
                  ? [
                      _c("h1", { staticClass: "page_title mt0" }, [
                        _vm._v("APPROVED"),
                      ]),
                    ]
                  : [
                      _vm.msgVo.doApvStsCd === "03"
                        ? _c("h1", { staticClass: "page_title mt0" }, [
                            _vm._v("APPROVED"),
                          ])
                        : _vm.msgVo.doApvStsCd === "02"
                        ? _c("h1", { staticClass: "page_title mt0" }, [
                            _vm._v("REQUEST"),
                          ])
                        : _vm.msgVo.doApvStsCd === "04"
                        ? _c("h1", { staticClass: "page_title mt0" }, [
                            _vm._v("REJECT"),
                          ])
                        : _vm.msgVo.doApvStsCd === "07"
                        ? _c("h1", { staticClass: "page_title mt0" }, [
                            _vm._v("PENDING"),
                          ])
                        : _c("h1", { staticClass: "page_title mt0" }, [
                            _vm._v("Available"),
                          ]),
                    ],
              ],
          ["JP", "VN", "TH", "MY", "PH"].indexOf(_vm.memberDetail.userCtrCd) ===
          -1
            ? _c(
                "div",
                { staticClass: "content_box" },
                [
                  _vm.msgVo.doStCd === "01" || _vm.msgVo.doStCd === "07"
                    ? [
                        _c("h4", [
                          _vm._v(
                            "[" + _vm._s(_vm.$t("msg.SETT010T010.102")) + "]"
                          ),
                        ]),
                        !_vm.$ekmtcCommon.isEmpty(_vm.picInfoList)
                          ? _c(
                              "div",
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("msg.SETT010T010.032")) +
                                    " : "
                                ),
                                _vm._l(_vm.picInfoList, function (pic, i) {
                                  return _c("p", { key: "pic-" + i }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(pic.usrNm || "") +
                                        " / " +
                                        _vm._s(pic.usrPhone || "") +
                                        " / " +
                                        _vm._s(pic.usrEmail || "") +
                                        " "
                                    ),
                                  ])
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.msgVo.podCtrCd === "KR",
                                        expression: "msgVo.podCtrCd === 'KR'",
                                      },
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.SETT010T010.207")) +
                                        " : DOCIS@EKMTC.COM"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.msgVo.doStCd === "07" &&
                                _vm.memberDetail.userCtrCd === "KR"
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.SETT010T010.033")) +
                                          " : " +
                                          _vm._s(_vm.$t("msg.KMTC118.007")) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _c("div", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CCIT100.034")) + " "
                              ),
                            ]),
                      ]
                    : _vm._e(),
                  _vm.msgVo.doStCd === "02" ||
                  _vm.msgVo.doStCd === "03" ||
                  _vm.msgVo.doStCd === "04" ||
                  _vm.msgVo.doStCd === "05" ||
                  _vm.msgVo.doStCd === "06" ||
                  _vm.msgVo.doStCd === "14"
                    ? [
                        _c("h4", [
                          _vm._v(
                            "[" + _vm._s(_vm.$t("msg.ONIM070P020.009")) + "]"
                          ),
                        ]),
                        _vm._l(_vm.reqErrMsg, function (msg, i) {
                          return _c("div", { key: "msg-" + i }, [
                            _vm._v(_vm._s(msg)),
                          ])
                        }),
                      ]
                    : _vm._e(),
                  _vm.msgVo.doStCd === "08" || _vm.msgVo.doStCd === "09"
                    ? [
                        _vm.$_.trim(_vm.msgVo.rejectCont) !== ""
                          ? [
                              _c("h4", [
                                _vm._v(
                                  "[" + _vm._s(_vm.$t("msg.CMBA380.009")) + "]"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(_vm.msgVo.rejectCont))]),
                              _c("br"),
                            ]
                          : _vm._e(),
                        _vm.doApvUser.doApvNm !== ""
                          ? [
                              _c("h4", [
                                _vm._v(
                                  "[" +
                                    _vm._s(_vm.$t("msg.ONIM070P020.005")) +
                                    " ]"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.SETT010T010.032")) +
                                    " : " +
                                    _vm._s(_vm.doApvUser.doApvNm)
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.msgVo.doStCd === "09"
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.SETT010T010.033")) +
                                        " : " +
                                        _vm._s(_vm.doApvUser.issuedBy)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.msgVo.doStCd === "10"
                    ? [
                        _c("h4", [
                          _vm._v(
                            "[" + _vm._s(_vm.$t("msg.SETT010T010.102")) + "]"
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("msg.SETT010T010.032")) +
                              " : " +
                              _vm._s(_vm.doApvUser.doApvNm)
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.msgVo.podCtrCd === "KR",
                                expression: "msgVo.podCtrCd === 'KR'",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("msg.SETT010T010.207")) +
                                " : DOCIS@EKMTC.COM"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("msg.SETT010T010.033")) +
                              " : " +
                              _vm._s(_vm.doApvUser.issuedBy)
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.msgVo.doStCd === "11" ||
                  _vm.msgVo.doStCd === "12" ||
                  _vm.msgVo.doStCd === "13"
                    ? [_c("div", [_vm._v(_vm._s(_vm.noPrintMsg))])]
                    : _vm._e(),
                  _vm.isDelegate === true
                    ? [
                        _c("p", { staticClass: "mt5" }, [
                          _c("span", { staticClass: "ml_auto cal_btn" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button sm",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fileDownLoad()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.202")))]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "txt_desc" }, [
                          _vm._v(_vm._s(_vm.$t("msg.SETT010T010.203"))),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _c(
                "div",
                { staticClass: "content_box" },
                [
                  _vm.msgVo.doApvStsCd === "02" && _vm.msgVo.doStsCd !== "1"
                    ? [_c("h4", [_vm._v("D/O issuance request.")])]
                    : _vm._e(),
                  (_vm.msgVo.doApvStsCd === "04" ||
                    _vm.msgVo.doApvStsCd === "07") &&
                  _vm.msgVo.doStsCd !== "1"
                    ? [
                        _vm.$_.trim(_vm.msgVo.doRejectCont) !== ""
                          ? [
                              _c("h4", [
                                _vm._v(
                                  "[" + _vm._s(_vm.$t("msg.CMBA380.009")) + "]"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.msgVo.doRejectCont)),
                              ]),
                              _c("br"),
                            ]
                          : _vm._e(),
                        _vm.msgVo.rejectUno !== ""
                          ? [
                              _c("h4", [
                                _vm._v(
                                  "[" +
                                    _vm._s(_vm.$t("msg.ONIM070P020.005")) +
                                    " ]"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.SETT010T010.032")) +
                                    " : " +
                                    _vm._s(_vm.doApvUser.doApvNm) +
                                    " " +
                                    _vm._s(_vm.doApvUser.doApvTel)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.SETT010T010.033")) +
                                    " : " +
                                    _vm._s(_vm.doApvUser.issuedBy)
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.msgVo.doApvStsCd === "03" || _vm.msgVo.doStsCd === "1"
                    ? [
                        _c("h4", [_vm._v("D/O issuance approved.")]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.doApvUser.doApvNm,
                                expression: "doApvUser.doApvNm",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("msg.SETT010T010.032")) +
                                " : " +
                                _vm._s(_vm.doApvUser.doApvNm)
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.doApvUser.doApvNm,
                                expression: "doApvUser.doApvNm",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("msg.SETT010T010.033")) +
                                " : " +
                                _vm._s(_vm.doApvUser.issuedBy)
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  (_vm.msgVo.doApvStsCd === "10" ||
                    _vm.msgVo.doApvStsCd === "") &&
                  _vm.msgVo.doStsCd !== "1"
                    ? [_c("h4", [_vm._v("D/O issuance can be requested.")])]
                    : _vm._e(),
                  _vm.isDelegate === true
                    ? [
                        _c("p", { staticClass: "mt5" }, [
                          _c("span", { staticClass: "ml_auto cal_btn" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button sm",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fileDownLoad()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.202")))]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "txt_desc" }, [
                          _vm._v(_vm._s(_vm.$t("msg.SETT010T010.203"))),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }