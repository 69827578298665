<template>
  <div class="popup_wrap" style="width:500px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <template v-if="['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(memberDetail.userCtrCd) === -1">
        <h1 class="page_title mt0">{{ pageTitle }}</h1>
      </template>
      <template v-else>
        <template v-if="msgVo.doStsCd === '1'">
          <h1 class="page_title mt0">APPROVED</h1>
        </template>
        <template v-else>
          <h1 v-if="msgVo.doApvStsCd === '03'" class="page_title mt0">APPROVED</h1>
          <h1 v-else-if="msgVo.doApvStsCd === '02'" class="page_title mt0">REQUEST</h1>
          <h1 v-else-if="msgVo.doApvStsCd === '04'" class="page_title mt0">REJECT</h1>
          <h1 v-else-if="msgVo.doApvStsCd === '07'" class="page_title mt0">PENDING</h1>
          <h1 v-else class="page_title mt0">Available</h1>
        </template>
      </template>

      <div v-if="['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(memberDetail.userCtrCd) === -1" class="content_box">
        <template v-if="msgVo.doStCd === '01' || msgVo.doStCd === '07'">
          <!-- 신청가능 , 발행요청 -->
          <!-- 발급/픽업정보 -->
          <h4>[{{ $t('msg.SETT010T010.102') }}]</h4>
          <div v-if="!$ekmtcCommon.isEmpty(picInfoList)">
            {{ $t('msg.SETT010T010.032') }}  :
            <p :key="'pic-' + i" v-for="(pic, i) in picInfoList">
              {{ pic.usrNm || '' }} / {{ pic.usrPhone || '' }} / {{ pic.usrEmail || '' }}
            </p>
            <div v-show="msgVo.podCtrCd === 'KR'">{{ $t('msg.SETT010T010.207') }} : DOCIS@EKMTC.COM</div> <!-- 발급 담당자 메일 -->
            <div v-if="msgVo.doStCd === '07' && memberDetail.userCtrCd ==='KR'">{{ $t('msg.SETT010T010.033') }} : {{ $t('msg.KMTC118.007') }} </div>
          </div>
          <div v-else>
            {{ $t('msg.CCIT100.034') }}
          </div>
        </template>

        <template v-if="msgVo.doStCd === '02' || msgVo.doStCd === '03' || msgVo.doStCd === '04' || msgVo.doStCd === '05' || msgVo.doStCd === '06' || msgVo.doStCd === '14'">
          <!-- 신청불가 -->
          <!-- 불가사유 -->
          <h4>[{{ $t('msg.ONIM070P020.009') }}]</h4>
          <div :key="'msg-' + i" v-for="(msg, i) in reqErrMsg">{{ msg }}</div>
        </template>

        <template v-if="msgVo.doStCd === '08' || msgVo.doStCd === '09'">
          <!-- 발행거절-거절사유 ,Pending-->
          <template v-if="$_.trim(msgVo.rejectCont) !== ''">
            <h4>[{{ $t('msg.CMBA380.009') }}]</h4> <!-- 사유 -->
            <div>{{ msgVo.rejectCont }}</div>
            <br>
          </template>
          <template v-if="doApvUser.doApvNm !== ''">
            <h4>[{{ $t('msg.ONIM070P020.005') }} ]</h4> <!-- 담당자 -->
            <div>{{ $t('msg.SETT010T010.032') }} : {{ doApvUser.doApvNm }}</div> <!-- 발급담당자 -->
            <div v-if="msgVo.doStCd === '09'">{{ $t('msg.SETT010T010.033') }} : {{ doApvUser.issuedBy }}</div> <!-- 발급처 -->
          </template>
        </template>

        <template v-if="msgVo.doStCd === '10'">
          <!-- 발행완료 -->
          <!-- 발급/픽업정보 -->
          <h4>[{{ $t('msg.SETT010T010.102') }}]</h4>
          <div>{{ $t('msg.SETT010T010.032') }} : {{ doApvUser.doApvNm }}</div> <!-- 발급담당자 -->
          <div v-show="msgVo.podCtrCd === 'KR'"> {{ $t('msg.SETT010T010.207') }} : DOCIS@EKMTC.COM</div> <!-- 발급 담당자 메일 -->
          <div>{{ $t('msg.SETT010T010.033') }} : {{ doApvUser.issuedBy }}</div> <!-- 발급처 -->
        </template>

        <template v-if="msgVo.doStCd === '11' || msgVo.doStCd === '12' || msgVo.doStCd === '13'">
          <!-- 출력불가 -->
          <div>{{ noPrintMsg }}</div>
        </template>

        <template v-if="isDelegate === true ">
          <p class="mt5"><span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="fileDownLoad()">{{ $t('msg.SETT010T010.202') }}</a></span></p>
          <div class="txt_desc">{{ $t('msg.SETT010T010.203') }}</div>
        </template>
      </div>

      <!-- 일본 D/O 신청일때 -->
      <div v-else class="content_box">
        <template v-if="msgVo.doApvStsCd === '02' && msgVo.doStsCd !== '1'">
          <!-- 요청중 -->
          <h4>D/O issuance request.</h4>
        </template>

        <template v-if="(msgVo.doApvStsCd === '04' || msgVo.doApvStsCd === '07') && msgVo.doStsCd !== '1' ">
          <!-- 발행거절-거절사유 ,Pending-->
          <template v-if="$_.trim(msgVo.doRejectCont) !== ''">
            <h4>[{{ $t('msg.CMBA380.009') }}]</h4> <!-- 사유 -->
            <div>{{ msgVo.doRejectCont }}</div>
            <br>
          </template>
          <template v-if="msgVo.rejectUno !== ''">
            <h4>[{{ $t('msg.ONIM070P020.005') }} ]</h4> <!-- 담당자 -->
            <div>{{ $t('msg.SETT010T010.032') }} : {{ doApvUser.doApvNm }} {{ doApvUser.doApvTel }}</div> <!-- 발급담당자 -->
            <div>{{ $t('msg.SETT010T010.033') }} : {{ doApvUser.issuedBy }}</div> <!-- 발급처 -->
          </template>
        </template>

        <template v-if="msgVo.doApvStsCd === '03' || msgVo.doStsCd === '1'">
          <!-- 발행완료 -->
          <!-- 발급/픽업정보 -->
          <h4>D/O issuance approved.</h4>
          <div v-show="doApvUser.doApvNm">{{ $t('msg.SETT010T010.032') }} : {{ doApvUser.doApvNm }}</div> <!-- 발급담당자 -->
          <div v-show="doApvUser.doApvNm">{{ $t('msg.SETT010T010.033') }} : {{ doApvUser.issuedBy }}</div> <!-- 발급처 -->
        </template>

        <template v-if="(msgVo.doApvStsCd === '10' || msgVo.doApvStsCd ==='') && msgVo.doStsCd !== '1'">
          <!-- 신청가능 , 발행요청 -->
          <!-- 발급/픽업정보 -->
          <h4>D/O issuance can be requested.</h4>
        </template>

        <template v-if="isDelegate === true ">
          <p class="mt5"><span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="fileDownLoad()">{{ $t('msg.SETT010T010.202') }}</a></span></p>
          <div class="txt_desc">{{ $t('msg.SETT010T010.203') }}</div>
        </template>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import calcIssueApi from '@/api/rest/settle/calcIssue'
import { rootComputed } from '@/store/helpers'
import myRequest from '@/api/rest/trans/myRequest'
import boards from '@/api/services/boards'

export default {
  name: 'CalcIssueDoMsgPop',
  components: {},
  props: {
    detail: {
      type: Object,
        default: function () {
        return {}
      }
    },
    isShowPop: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      pageTitle: '', //'제목',
      isShow: false,
      errorCodes: [],
      msgData: {},
      msgVo: {},
      surrenderDoDetail: {},
      csdBlDetail: {},
      trmlCd: '',
      isDelegate: false,
      surrender: {
        blStatus: '',
        frStatus: '',
        demStatus: '',
        doStatus: ''
      },
      trmlInfo: {
        trmlNm: '',
        trmlDock: ''
      },
      doApvUser: {
        doApvNm: '',
        doApvTel: '',
        issuedBy: ''
      },
      reqErrMsg: [],
      noPrintMsg: '',
      picInfoList: []
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    isShowPop (nv, ov) {
      this.isShow = nv
    },
    detail: {
      handler () {
        this.setData()
      },
      deep: true
    }
  },
  async created () {
    // 기초 Data 셋팅
    await this.getBlErrorCodes()
  },
  async mounted () {
    if (this.errorCodes.length === 0) {
      await this.getBlErrorCodes()
      this.setData()
    }
  },
  methods: {
    setData () {
      console.log('## CalcIssueDoMsgPop > Set Data')
      this.msgData = this.$_.cloneDeep(this.detail)
      console.log(this.msgData)
      this.displayMsg()
    },
    async displayMsg () {
      const vo = {
        blNo: this.msgData.blNo,
        obrdDt: this.msgData.obrdDt ? this.msgData.obrdDt : '',
        bkgShprCstNo: this.msgData.bkgShprCstNo ? this.msgData.bkgShprCstNo : '',
        blStCd: this.msgData.blStCd ? this.msgData.blStCd : '00',
        doStCd: this.msgData.doStCd ? this.msgData.doStCd : '00',
        loiReqCatCd: this.msgData.loiReqCatCd ? this.msgData.loiReqCatCd : '',
        loiEtcRmk: this.msgData.loiEtcRmk ? this.msgData.loiEtcRmk : '',
        loiTelNo: this.msgData.loiTelNo ? this.msgData.loiTelNo : '',
        cntrCfsYn: this.msgData.cntrCfsYn,
        certiYn: this.msgData.certiYn,
        elYn: this.msgData.elYn,
        rejectCont: this.msgData.rejectCont,
        cntrStsCd: this.msgData.cntrStsCd ? this.msgData.cntrStsCd : '',
        blErrCd: this.msgData.blErrCd ? this.msgData.blErrCd : '',
        doApvStsCd: this.msgData.doApvStsCd ? this.msgData.doApvStsCd : '',
        doStsCd: this.msgData.doStsCd ? this.msgData.doStsCd : '',
        doRejectCont: this.msgData.doRejectCont ? this.msgData.doRejectCont : '',
        doApprNm: this.msgData.doApprNm ? this.msgData.doApprNm : '',
        doApprUno: this.msgData.doApprUno ? this.msgData.doApprUno : '',
        doApprTel: this.msgData.doApprTel ? this.msgData.doApprTel : '',
        doApprArea: this.msgData.doApprArea ? this.msgData.doApprArea : '',
        rejectNm: this.msgData.rejectNm ? this.msgData.rejectNm : '',
        rejectUno: this.msgData.rejectUno ? this.msgData.rejectUno : '',
        rejectTel: this.msgData.rejectTel ? this.msgData.rejectTel : '',
        rejectAreaNm: this.msgData.rejectAreaNm ? this.msgData.rejectAreaNm : '',
        podPortCd: this.msgData.podPortCd ? this.msgData.podPortCd : '',
        podCtrCd: this.msgData.podCtrCd ? this.msgData.podCtrCd : ''
      }
      this.msgVo = vo

      console.log('vo.doStCd >>> , ', vo.doStCd)

      // 내용 중 위임장이 있으면 위임장 다운로드 가능하도록.
      if (this.msgVo.rejectCont.indexOf('위임장') > -1) {
        this.isDelegate = true
      }

      if (this.msgVo.doStCd === '01' || this.msgVo.doStCd === '01') { // 신청가능, 발행요청
        this.userIngListFnc(this.msgVo)
      }

      const params = {
        blNo: this.msgData.blNo
      }
      await calcIssueApi.getDoDetail(params)
        .then(async response => {
          console.log('> getDoInfo >>', response)
          if (response.data) {
            this.surrenderDoDetail = response.data.surrenderDoDetail
            this.csdBlDetail = response.data.csdBlDetail
            this.trmlCd = response.data.trmlCd
          }
        })

      // if (vo.doStCd === '01' || vo.doStCd === '07' || vo.doStCd === '10') {
      if (vo.doStCd === '01' || vo.doStCd === '07' || vo.doStCd === '10') {
        this.pageTitle = this.$t('msg.SETT010T010.102') // '발급/픽업정보'
      } else if (vo.doStCd === '02' || vo.doStCd === '03' || vo.doStCd === '04' || vo.doStCd === '05' || vo.doStCd === '06' || vo.doStCd === '14') {
        this.pageTitle = this.$t('msg.ONIM070P020.009') //'불가사유'
      } else if (vo.doStCd === '08') {
        this.pageTitle = this.$t('msg.ONIM070P020.044') //'거절사유'
      } else if (vo.doStCd === '09') {
        this.pageTitle = 'Pending' + this.$t('msg.CMBA380.009') // 사유
      } else if (vo.doStCd === '11' || vo.doStCd === '12' || vo.doStCd === '13') {
        this.pageTitle = this.$t('msg.CMBA380.009') // '출력불가'
      }

      this.reqErrMsg = []
      if (vo.doStCd === '02') {
        this.reqErrMsg.push(this.$t('msg.ADD20220221.010')) // '선적지에서 B/L이 미발행되었습니다.'
        // this.reqErrMsg.push(this.$t('msg.ADD20220221.022')) // 발급이 불가합니다. 당사로 문의바랍니다. (TEL : 051-797-7400. 내선 5, 내선 6)
      } else if (vo.doStCd === '03') {
        this.reqErrMsg.push(this.$t('msg.ADD20220221.012')) // 'O.B/L 미접수되었습니다.'
        this.reqErrMsg.push(this.$t('msg.ADD20220221.022')) // '발급이 불가합니다. 당사로 문의바랍니다. (TEL : 051-797-7400. 내선 5, 내선 6)'
      } else if (vo.doStCd === '04') {
        this.reqErrMsg.push(this.$t('msg.ADD20220221.013')) // 'KL-NET으로 D/O 발급이 진행 중입니다.'
      } else if (vo.doStCd === '05') {
        this.reqErrMsg.push(this.$t('msg.ADD20220221.014')) // KT-NET으로 D/O 발급이 진행 중입니다.
      } else if (vo.doStCd === '06') {
        this.reqErrMsg.push(this.$t('msg.ADD20220221.015')) // 운임이 등록되지 않았습니다. 선적지 및 영업사원 문의바랍니다.
      } else if (vo.doStCd === '14') {
        this.reqErrMsg.push(this.$t('msg.ADD20220221.022')) // 발급이 불가합니다. 당사로 문의바랍니다. (TEL : 051-797-7400. 내선 5, 내선 6)
      }

      this.noPrintMsg = ''
      if (vo.doStCd === '11') {
        this.noPrintMsg = this.$t('msg.ADD20220221.016') // 종이 D/O를 발급하였습니다. 홈페이지에서 출력 불가합니다.
      } else if (vo.doStCd === '12') {
        this.noPrintMsg = this.$t('msg.ADD20220221.018') // KT-NET으로 D/O 승인되었습니다. KL-NET에서 출력 가능합니다.
      } else if (vo.doStCd === '13') {
        this.noPrintMsg = this.$t('msg.ADD20220221.017') //KL-NET으로 D/O 승인되었습니다. KT-NET에서 출력 가능합니다.
      }

      let tmpMsg = ''
      const blSts = this.surrenderDoDetail.blSts
      if (blSts === 'A1') {
        tmpMsg = this.$t('msg.ONIM050G010.011') //'O.B/L 접수'
      } else if (blSts === 'A2') {
        tmpMsg = this.$t('msg.ONIM050G010.012') //'Bank L/G 접수'
      } else if (blSts === 'A3') {
        tmpMsg = 'SURENDER B/L'
      } else if (blSts === 'A4') {
        tmpMsg = 'WAYBILL'
      } else if (blSts === 'A5') {
        tmpMsg = '<span class="red">' + this.$t('msg.ONIM050G010.011') + '</span>' //O.B/L 미접수
      } else if (blSts === 'A6') {
        tmpMsg = '<span class="red">' + this.$t('msg.ONIM050G010.015') + '</span>' //  O.B/L 미접수
      } else if (blSts === 'A7') {
        tmpMsg = '<span class="red">WAY BILL ' + this.$t('msg.CSBL100_M1.008') + '</span>' // 미발행
      } else if (blSts === 'A8') {
        tmpMsg = '<span class="red">O.B/L, SUR ' + this.$t('msg.CSBL100_M1.008') + '</span>' //미발행
      }
      this.surrender.blStatus = tmpMsg

      const frSts = this.surrenderDoDetail.frSts
      tmpMsg = ''
      if (frSts === '01') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.018') + '</font></span>' // 미정산(영수)
      } else if (frSts === '02') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.019') + '</font></span>' // 미정산(개별청구)
      } else if (frSts === '03') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.020') + '</font></span>' // 미정산(월말청구)
      } else if (frSts === '04') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.021') + '</font></span>' // 외상한도 초과(개별청구)
      } else if (frSts === '05') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.022') + '</font></span>' // 외상한도 초과(월말청구)
      } else if (frSts === '11') {
        tmpMsg = '<span>' + this.$t('msg.ONIM050G010.023') + '</span>' // 정산완료(영수)
      } else if (frSts === '12') {
        tmpMsg = '<span>' + this.$t('msg.ONIM050G010.024') + '</span>' // 정산완료(개별청구)
      } else if (frSts === '13') {
        tmpMsg = '<span>' + this.$t('msg.ONIM050G010.025') + '</span>' // 정산완료(월말청구)
      } else if (frSts === '14') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.026') + '</font></span>' // 운임미확정(영수)
      } else if (frSts === '15') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.027') + '</font></span>' // 운임미확정(개별청구)
      } else if (frSts === '16') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.028') + '</font></span>' // 운임미확정(월말청구)
      }
      this.surrender.frStatus = tmpMsg

      const demSts = this.surrenderDoDetail.demSts ? this.surrenderDoDetail.demSts : ''
      const ovrDds = this.surrenderDoDetail.ovrDds ? this.surrenderDoDetail.ovrDds : '0'
      tmpMsg = '<span>' + this.$t('msg.ONIM050G010.029') + '</span>'
      if (demSts === 'Y') {
        tmpMsg = `<span class="red">발생 (${ovrDds} 일 경과)</span>`
      }
      this.surrender.demStatus = tmpMsg

      const doStsCd = this.surrenderDoDetail.doStsCd ? this.surrenderDoDetail.doStsCd : ''
      tmpMsg = ''
      if (doStsCd === '8' || doStsCd === '6' || doStsCd === '7' || doStsCd === '5' || doStsCd === '4') {
        tmpMsg = '<span class="red"><font color="red">' + this.$t('msg.ONIM050G010.031') + '</font></span>'
      } else if (doStsCd === '9' || doStsCd === '3') {
        tmpMsg = '<span>' + this.$t('msg.ONIM050G010.032') + '</span>' // D/O 발행요청
      } else if (doStsCd === '8' || doStsCd === '2') {
        tmpMsg = '<span>' + this.$t('msg.ONIM050G010.033') + '</span>' // D/O 발행
      }
      this.surrender.doStatus = tmpMsg

      const trmlCd = this.trmlCd ? this.trmlCd : ''
      tmpMsg = ''
      if (trmlCd === 'UTC') {
        tmpMsg = '051-640-6782'
      } else if (trmlCd === 'DPCT') {
        tmpMsg = '051-630-3424'
      } else if (trmlCd === 'BGCT') {
        tmpMsg = '051-630-8522'
      } else if (trmlCd === 'KBCT') {
        tmpMsg = '051-620-0314'
      } else if (trmlCd === 'CONV') {
        tmpMsg = '051-600-1436'
      }
      this.trmlInfo.trmlNm = this.csdBlDetail.podTrmlNm
      this.trmlInfo.trmlDock = tmpMsg

      this.doApvUser.doApvNm = this.surrenderDoDetail.doApvNm ? this.surrenderDoDetail.doApvNm : ''
      const plcCd = this.surrenderDoDetail.plcCd ? this.surrenderDoDetail.plcCd : ''
      if (plcCd === 'SEL') {
        this.doApvUser.issuedBy = this.$t('msg.KMTC118.006') //'서울특별시 중구 남대문로2가 118 한진빌딩 15층(신관) 고려해운 DESK'
      } else if (plcCd === 'PUS') {
        this.doApvUser.issuedBy = this.$t('msg.KMTC118.007') //'부산광역시 중구 중앙동4가 88-7 교보생명빌딩 10층'
      } else if (plcCd === 'USN') {
        this.doApvUser.issuedBy = this.$t('msg.KMTC118.008') // '울산광역시 남구 용연로 370 (황성동) 울산동방아이포트 4층'
      } else if (plcCd === 'INC') {
        this.doApvUser.issuedBy = this.$t('msg.KMTC118.010') //'인천 광역시 중구 신흥동 3가 7-241 정석빌딩 신관 8층'
      }

      if (['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) >= 0) {
        if (this.msgVo.doApvStsCd === '04' || this.msgVo.doApvStsCd === '07') { // 거절, PENDING
          this.doApvUser.doApvNm = this.msgVo.rejectNm ? this.msgVo.rejectNm : ''
          this.doApvUser.doApvTel = this.msgVo.rejectTel ? this.msgVo.rejectTel : ''
          this.doApvUser.issuedBy = this.msgVo.rejectAreaNm ? this.msgVo.rejectAreaNm : ''
          // plcCd = this.msgVo.oblArea
        } else if (this.msgVo.doApvStsCd === '03' || this.msgVo.doStsCd === '1') { // 승인
          this.doApvUser.doApvNm = this.msgVo.doApprNm ? this.msgVo.doApprNm : ''
          this.doApvUser.doApvTel = this.msgVo.doApprTel ? this.msgVo.doApprTel : ''
          this.doApvUser.issuedBy = this.msgVo.doApprArea ? this.msgVo.doApprArea : ''
          // plcCd = this.msgVo.surArea
        }
      }
    },

    async getDoInfo () {
      const params = {
        blNo: this.msgData.blNo
      }
      await calcIssueApi.getDoDetail(params)
        .then(async response => {
          console.log('>> getDoInfo >>', response)
        })
    },

    async getBlErrorCodes () {
      await calcIssueApi.getBlErrorCodes()
        .then(async response => {
          this.errorCodes = response.data
        })
    },
    userIngListFnc (rvo) {
      let isFlag = false

      const THIS = this
        myRequest.getUserIngInfo({ blNo: rvo.blNo, ntcCd: '06' }).then(res => {
        const vo = res.data
        if (vo) {
           THIS.picInfoList = vo.myRequestUserList
          isFlag = true
        }
      })
      return isFlag
    },
    fileDownLoad () {
      // 자료실에 업로드 된 위임장 첨부파일 다운로드.
      const formData = {
        fileId: '1',
        messageIdSeq: 'CKR38',
        groupId: '38',
        fileName: 'KMTC 업무위임장(2023.01.17).doc'
      }

      boards.fileDownload(formData)
        .then(response => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE에서 동작
            window.navigator.msSaveOrOpenBlob(response.data, formData.fileName)
          } else {
            console.log(response.data)
            //CHROME에서 동작
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', formData.fileName)
            link.click()
            link.remove()

            window.URL.revokeObjectURL(url)
          }
        })
    }
  }
}
</script>
